import { AxiosInstance } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV3, apiUrlV4 } from 'src/services/constants/api-versions';
import {
  IGetGames, IGetGame, IConfirmAndFinalizeGame, IManuallyFedScores,
} from 'src/services/interfaces/Games.interface';
import { ErrorRes } from 'src/types';
import dateFormat from 'src/util/dateFormat/dateFormat';

export const GamesService = (axios:AxiosInstance) => {
  const useGetGameDetail = (scheduleId: string) => {
    const isDev = window.location.hash === '#/calendar-dev';
    const apiFolder = isDev ? 'getGameDetailDev' : 'getGameDetail';
    const getGameDetail = () => axios
      .get(`${apiUrlV3}game/${apiFolder}/${scheduleId}`)
      .then(((res) => res.data));

    return useQuery(
      ['query-get-game-detail', scheduleId],
      getGameDetail,
      {
        onError: (error) => {
          snackbarService.error('Error getting game detail. Try again later.');
          console.error('Error when trying to get game detail:', error);
        },
        refetchOnWindowFocus: false,
        enabled: !!scheduleId, // Fetch when has the params
      },
    );
  };

  const useGetGames = (params: IGetGames) => {
    const dateParams = {
      date: dateFormat(params?.date, 'YYYY-MM-DD'),
      end_date: dateFormat(params?.end_date, 'YYYY-MM-DD'),
    };

    const getGames = () => axios.post(
      `${apiUrlV4}game/getGamesBySchool`,
      { ...params, ...dateParams, offset: new Date().getTimezoneOffset() },
    ).then((res) => res.data);

    return useQuery(
      {
        queryKey: ['query-games', params],
        queryFn: getGames,
        onError: (error) => {
          snackbarService.error('Error getting games. Try again later.');
          console.error('Error when trying to get the games:', error);
        },
        staleTime: Infinity,
        cacheTime: 2000,
        refetchOnWindowFocus: false,
        enabled: !!params, // Fetch when has the params
        keepPreviousData: true,
      },
    );
  };

  const GetGamesPitchCountMultipleEntry = () => {
    const getGamesPitchCountMultipleEntry = (payload: IGetGames) => axios.post(
      `${apiUrlV4}game/getGamesBySchool`,
      { ...payload, offset: new Date().getTimezoneOffset() },
    ).then((res) => res.data);

    return useMutation(getGamesPitchCountMultipleEntry, {
      onError: (error) => {
        snackbarService.error('Error getting games. Try again later.');
        console.error('Error when trying to get the games:', error);
      },
    });
  };

  const ManuallyFedScores = () => {
    const manuallyFedScores = (payload: IManuallyFedScores) => {
      return axios
        .post(`${apiUrlV4}manually-fed-scores/save-scores`, payload)
        .then((res) => res.data);
    };

    return useMutation(manuallyFedScores, {
      onSuccess: () => {
        snackbarService.success('Scores reported successfully!');
      },
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error('Error when try to save scores:', error);
      },
    });
  };

  const ConfirmAndFinalizeGame = () => {
    const confirmAndFinalizeGame = (payload: IConfirmAndFinalizeGame) => {
      return axios
        .post(`${apiUrlV4}game/confirm-and-finalize`, payload)
        .then((res) => res.data);
    };

    return useMutation(confirmAndFinalizeGame, {
      onSuccess: () => {
        snackbarService.success('Scores confirmed and finalized successfully!');
      },
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error('Error when try to finalize game:', error);
      },
    });
  };

  const GetGameById = (params: IGetGame) => {
    return axios.get(`${apiUrlV3}game/${params}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  return {
    useGetGameDetail,
    useGetGames,
    GetGamesPitchCountMultipleEntry,
    GetGameById,
    ManuallyFedScores,
    ConfirmAndFinalizeGame,
  };
};
