import { createAction, createReducer } from '@reduxjs/toolkit';

/* Initial state */
const initialState = {
  associationsList: null,
};

/* Actions */
const setAssociationsList = createAction('association/setAssociationsList');

/* Reducer */
export const associationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAssociationsList, (state, action) => {
    state.associationsList = action.payload;
  });
});
