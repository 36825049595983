import React from 'react';

import cn from 'classnames';
import CloseIcon from 'src/assets/images/closeNew.svg';
import QuestionBlueIcon from 'src/assets/images/modal/question-blue-icon.svg';
import QuestionRedIcon from 'src/assets/images/modal/question-red-icon.svg';
import Button from 'src/components/common/button/CustomButton';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import { ColorModalEnums } from 'src/enums/ColorModalEnums';

import styles from './ConfirmationModal.module.css';
import { ConfirmationModalProps } from './ConfirmationModalNew.types';

const { RED, BLUE } = ColorModalEnums;

const ConfirmationModalNew = (props: ConfirmationModalProps) => {
  const {
    type = BLUE,
    title,
    text,
    approveFn,
    cancelFn,
    closeFn,
    approveBtnTitle = 'Yes',
    cancelBtnTitle = 'No',
  } = props;

  return (
    <ModalWindow isOpen className={styles.modal}>
      <Button version="icon" onClick={closeFn || cancelFn} className={styles.closeIcon}>
        <img
          src={CloseIcon}
          alt="close"
        />
      </Button>
      <div className={styles.container}>
        <img src={type === BLUE ? QuestionBlueIcon : QuestionRedIcon} className={styles.typeIcon} alt="type-icon" />
        <div className={styles.header}>
          <p className={styles.title}>{title}</p>
          <p className={styles.text}>{text}</p>
        </div>

        <div className={styles.actions}>
          <Button className={cn(styles.approve, type === RED && styles.approveRed)} onClick={approveFn}>{approveBtnTitle}</Button>
          <Button className={cn(styles.cancel, type === RED && styles.cancelRed)} onClick={cancelFn}>{cancelBtnTitle}</Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ConfirmationModalNew;
