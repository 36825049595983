import React from 'react';

import cn from 'classnames';
import { components, OptionProps } from 'react-select';

import styles from '../Select.module.css';

type SingleValueProps = OptionProps & {
  selectProps: {
    deviceStatus: string,
  }
  data: {
    comment: string,
  }
};

const SingleValue = (props: SingleValueProps) => {
  const { selectProps, children, data } = props;

  return (
    <components.SingleValue {...props}>
      {selectProps.deviceStatus ? (
        <span
          className="online-status mr-lg-2 "
          style={{ backgroundColor: selectProps.deviceStatus }}
        />
      ) : null}
      {children}
      {data?.comment && (
        <span className={cn(styles.comment)}>
          {' '}{data.comment}
        </span>
      )}
    </components.SingleValue>
  );
};

export default SingleValue;
