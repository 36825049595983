import React from 'react';

import cn from 'classnames';
import Tooltip from 'src/components/common/tooltip/Tooltip';

import styles from './CustomButton.module.css';
import { CustomButtonProps } from './CustomButton.props';

const CustomButton = ({
  children, version, onMouseDown, onClick, className, disabled = false, title, tooltip, dataTest
}:CustomButtonProps) => {
  switch (version) {
    case 'primary':
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, styles.primary, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
    case 'secondary':
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, styles.secondary, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
    case 'outline':
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, styles.outline, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
    case 'transparent':
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, styles.transparent, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
    case 'icon':
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, styles.icon, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
    case 'link':
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, styles.link, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
    default:
      return (
        <Tooltip content={tooltip} disabled={!tooltip}>
          <button disabled={disabled} title={title} type="button" className={cn(styles.button, className)} onMouseDown={onMouseDown} onClick={onClick} data-test={dataTest}>{children}</button>
        </Tooltip>
      );
  }
};

export default CustomButton;
