import React, { FC, useState } from 'react';

import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import CloseIcon from 'src/assets/images/menu-icons/close.svg';
import stop from 'src/assets/images/not-allowed.svg';

import styles from './ErrorModal.module.css';
import { ErrorModalProps } from './ErrorModal.props';

const ErrorModal: FC<ErrorModalProps> = ({ optionalText }) => {
  /*
  * Exports trigger from store:
  * const { errorTrigger } = useSelector(store => store.errorTrigger);
  * */
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  dispatch({
    type: 'setErrorTrigger',
    payload: () => setIsOpen((prevState) => !prevState),
  });

  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.modal}
      appElement={document.querySelector('root') as HTMLElement}
      overlayClassName={styles.overlay}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsOpen(false)}
    >
      <div className={styles.wrapper}>
        <button onClick={() => setIsOpen(false)} className={styles.close}><img src={CloseIcon} alt="x" /></button>
        <h2>Error</h2>
        <img className={styles.stop} src={stop} alt="not-allowed" />
        <p>{optionalText || 'Error happened :('}</p>
      </div>
    </ReactModal>
  );
};

export default ErrorModal;
