import { createAction, createReducer } from '@reduxjs/toolkit';

interface IGenders {
  _id: string;
  name: string;
}

/* Initial state */
const initialState = {
  genders: null,
};

/* Actions */
const setGenders = createAction('genders/setGenders', (genders: IGenders[]) => ({
  payload: genders,
}));

/* Reducer */
export const gendersReducer = createReducer(initialState, (builder) => {
  builder.addCase(setGenders, (state, action) => {
    state.genders = action.payload;
  });
});
