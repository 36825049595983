import { validatePhoneNumber } from 'src/util/validatePhoneNumber';
import { string } from 'yup';

export const phoneNumberSchema = string()
  .notRequired()
  .nullable()
  .when('phoneNumber', { // validation depends on this field or another
    is: (phoneNumberInputValue: string) => !!phoneNumberInputValue,
    then: string().test(
      'phoneNumber',
      validatePhoneNumber,
    ),
  });
