/* eslint-disable */
const initialState = {
  detailed_List: [],
  last_fetched_date: new Date(),

};
const shadows = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHADOW_LIST':
      return { ...state, detailed_List: action.shadowRes };

    default:
      return state;
  }
};

export default shadows;
