import React from 'react';

import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import cn from 'classnames';
import { components, OptionProps } from 'react-select';
import AddIcon from 'src/assets/images/add-player.svg';

import styles from '../Select.module.css';

export type CustomOptionProps = OptionProps & {
  data: {
    label: string;
    more?: boolean;
    add?: boolean;
    comment?: string;
    isDisabled?: boolean;
    isSelected?: boolean;
  };
  selectProps: {
    onMoreClick?: () => void;
    onAddClick?: () => void;
  };
};

const Option = (props: CustomOptionProps) => {
  const {
    data,
    selectProps,
    isSelected,
    children,
  } = props;

  if (data?.more) {
    return (
      <button
        className={cn(styles.button, styles.more)}
        onClick={selectProps.onMoreClick}
      >
        <div>{data.label}</div>
        <ChevronRightIcon label="load more" />
      </button>
    );
  }

  if (data?.add) {
    return (
      <button
        className={cn(styles.button, styles.add)}
        onClick={selectProps?.onAddClick}
      >
        <img src={AddIcon} alt="add" />
        <div className={styles.addLabel}>{data.label}</div>
      </button>
    );
  }

  return (
    <components.Option
      {...props}
      className={cn(styles.customOption, data.isDisabled ? styles.disabled : '')}
    >
      {children}
      {/* Uses in Device, Wifi Setup: */}
      {data?.comment && (
        <span className={cn(styles.comment, isSelected && styles.commentSelected)}>
          {' '}{data.comment}
        </span>
      )}
    </components.Option>
  );
};

export default Option;
