import React from 'react';

import { components } from 'react-select';
import AddIcon from 'src/assets/images/add-white-plus-icon.svg';
import Button from 'src/components/common/button/CustomButton';

import styles from './AddTeamButton.module.css';

export const AddTeamButton = (props) => {
  const { menuIsOpen, selectProps, children } = props;

  const { onAddTeamClick, value } = selectProps;

  const onMouseDown = (e) => {
    onAddTeamClick();
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <components.Control {...props}>
      {(onAddTeamClick && !menuIsOpen && !value) ? (
        <Button
          onMouseDown={onMouseDown}
          className={styles.addTeamBtn}
          onClick={() => {}}
        >
          <img className={styles.addIcon} src={AddIcon} alt="create-automatically-icon" />
          Create Automatically
        </Button>
      ) : null}
      {children}
    </components.Control>
  );
};
