import React, { ReactElement } from 'react';

import Tippy from '@tippyjs/react';

import { TooltipProps } from './Tooltip.props';
// because it transition dep @tippyjs/react
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/themes/light.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';
import './Tooltip.module.css';

const Tooltip = ({
  children,
  content,
  placement,
  theme,
  ...props
}:TooltipProps) => {

  return (
    <Tippy
      placement={placement}
      content={content}
      theme={theme}
      {...props}
    >
      {children as ReactElement}
    </Tippy>
  );
};

export default Tooltip;
