import axiosInstance from 'axios';
import { Service } from 'axios-middleware';
import { DevicesService } from 'src/services/api/DevicesService';
import { FacilityService } from 'src/services/api/FacilityService';
import { GamesService } from 'src/services/api/GamesService';
import {
  GetOpponents, GetRecentOpponents, RemoveOpponents, SaveOpponents,
} from 'src/services/api/OpponentsService';
import { SaveSchedulesBatch, SchedulesService, SetScheduleSeason } from 'src/services/api/SchedulesService';
import { SchoolsService } from 'src/services/api/SchoolsService';
import { TeamsService } from 'src/services/api/TeamsService';
import { GameAdminService } from 'src/services/new-scorebird-services/game-admin-services/gameadmin.service';
import { getIdToken } from 'src/util/getIdToken';

import { ApiKeyService } from './api/ApiKeyService';
import { AuthService } from './api/AuthService';
import { FiltersService } from './api/FiltersService';
import { GamesAdminService } from './api/GamesAdminService';
import { GenderService } from './api/GenderService';
import { GradeService } from './api/GradeService';
import { NetworkService } from './api/NetworkService';
import { GetAllNotifications } from './api/NotificationService';
import { PitchCountService } from './api/PitchCountService';
import { ProfileReportService } from './api/ProfileReportService';
import { ShadowService } from './api/ShadowService';
import { SportService } from './api/SportService';
import { SubscriptionsService } from './api/SubscriptionsService';
import { TwitterService } from './api/TwitterService';
import { UserService } from './api/UserService';
import { UserLogOut } from './auth';
import allowedURL from './constants/allowedURL';

// Create instance and default config options
const createAxios = () => {
  return axiosInstance.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const axios = createAxios();

axios.interceptors.request.use(async (config) => {
  await getIdToken().then((idToken) => {
    if (idToken !== null) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
  });
  return config;
});

new Service(axios).register({
  onRequest(config) {
    // Check if this request URL is valid
    if (allowedURL.some((url: string) => config.url?.match(url))) {
      return config;
    }
    return config;
  },

  onSync(promise) {
    return promise;
  },

  onResponse(response) {
    return response;
  },

  onResponseError(error) {
    const data = JSON.parse(error?.response?.data);
    if ((error?.response.status === 403) && (data.message === 'Authentication required')) {
      UserLogOut();
    }
    throw error;
  },
});
// Add here new API requests
const api = {
  authService: () => AuthService(axios),
  userService: () => UserService(axios),
  pitchCountService: () => PitchCountService(axios),
  schoolsService: () => SchoolsService(axios),
  sportService: () => SportService(axios),
  gradeService: () => GradeService(axios),
  genderService: () => GenderService(axios),
  gameAdminService: () => GameAdminService(axios),
  profileReport: () => ProfileReportService(axios),
  devicesService: () => DevicesService(axios),
  networkService: () => NetworkService(axios),
  scheduleService: () => SchedulesService(axios),
  SaveSchedulesBatch: () => SaveSchedulesBatch(axios),
  SetScheduleSeason: () => SetScheduleSeason(axios),
  facilityService: () => FacilityService(axios),
  teamsService: () => TeamsService(axios),
  gamesService: () => GamesService(axios),
  getOpponents: (schoolId: string) => GetOpponents(axios, schoolId),
  getRecentOpponents: (schoolId: string) => GetRecentOpponents(axios, schoolId),
  setOpponents: (schoolId: string) => SaveOpponents(axios, schoolId),
  removeOpponents: (schoolId: string) => RemoveOpponents(axios, schoolId),
  getAllNotifications: () => GetAllNotifications(axios),
  subscriptionsService: () => SubscriptionsService(axios),
  apiKeyService: () => ApiKeyService(axios),
  twitterService: () => TwitterService(axios),
  shadowService: () => ShadowService(axios),
  filtersService: () => FiltersService(axios),
  gamesAdminV2Service: () => GamesAdminService(axios),
};

export default api;
