import { createAction, createReducer } from '@reduxjs/toolkit';

/* Interface */
interface Sports {
  data: any,
  detailedList: any,
}

/* Initial State */
const initialState = {
  sports: null,
  detailedList: null,
};

/* Actions */
const setSports = createAction('sports/setSports', (sports: Sports) => ({
  payload: sports,
}));

/* Reducer */
export const sportsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSports, (state, action) => {
    state.sports = action.payload?.data;
    state.detailedList = action.payload?.detailedList;
  });
});
