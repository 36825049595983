import React from 'react';

import cn from 'classnames';
import Flags from 'country-flag-icons/react/3x2';

import styles from './SelectCountryOption.module.css';
import { SelectCountryOptionProps } from './SelectCountryOption.types';

export const SelectCountryOption = (props: SelectCountryOptionProps) => {
  const {
    innerProps,
    isSelected,
    data,
  } = props;

  const Flag = Flags[data.value];

  return (
    <div {...innerProps} className={cn(styles.selectCountryOptionContainer, isSelected && styles.isSelected)}>
      <Flag
        title={data.value}
        className={styles.selectCountryOptionFlag}
      />
      <span className={styles.selectCountryOptionText}>{data.value}</span>
    </div>
  );
};
