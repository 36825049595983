import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export function validatePhoneNumber(phoneNumberInputValue: string) {
  const { path, createError } = this;
  try {
    const numberWithPlus = phoneNumberInputValue ? `+${phoneNumberInputValue}` : null;
    const phoneNumber = phoneUtil.parse(numberWithPlus);
    const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
    if (!phoneUtil.isValidNumberForRegion(phoneNumber, regionCode)) {
      return createError({
        path,
        message: 'Please enter a valid phone number in your country',
      });
    }
    return true;
  } catch (error) {
    return createError({
      path,
      message: 'Please enter a valid phone number',
    });
  }
}
