import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { RootState } from 'src/store/types/rootStateTypes';

const useSocket = () => {
  const { token } = useSelector((state: RootState) => state.token);

  if (token) {
    return io(
      process.env.REACT_APP_API,
      {
        auth: {
          token: `Bearer ${token}`,
        },
        withCredentials: true,
        autoConnect: false,
      },
    );
  }

  return null;
};

export default useSocket;
