/* eslint-disable */
import React from 'react';

import cn from 'classnames';
import Select, { components, createFilter, mergeStyles } from 'react-select';
import { List } from 'react-virtualized';
import { InputCheckbox } from 'src/components/common/input-checkbox/InputCheckbox';
import { SelectProps } from 'src/components/common/select/Select.props';

import './style.css';
import { AddTeamButton } from './components/addTeamButton/AddTeamButton';
import { DropdownIndicator } from './components/DropdownIndicator';
import { LoadingIndicator } from './components/LoadingIndicator';
import { MultiValueRemove } from './components/MultiValueRemove';
import styles from './Select.module.css';
import Option from "./components/Option";
import AddButtonFooter from "./components/addButtonFooter/AddButtonFooter";
import GroupHeading from "../select-async/groupHeading/GroupHeading";
import Control from "./components/control/Control";
import multiSelect from "./styles/multiSelect";
import filterSelect from "./styles/filterSelect";
import SingleValue from "./components/SingleValue";

export const style = {
  select: {
    container: (provided) => ({
      ...provided,
      marginBottom: 19,
      position: 'relative',
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 50,
      backgroundColor: '#fff',
      borderRadius: 40,
      boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
      padding: 0,
      width: '100%',
      border: state.selectProps.errorMsg ? '1px solid red' : 'none',
      ':hover': {
        boxShadow: state.selectProps.isReadOnly ? 'var(--non-focus-shadow)' : '0px -1px 13px rgba(114, 152, 241, 0.5)',
        borderColor: 'red',
      },
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: 16,
      marginLeft: 22,
      padding: 0,
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
    }),
    menu: (provided, state) => ({
      ...provided,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 15,
      fontSize: 16,
      zIndex: 15,
      boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
      '::after': {
        content: '\' \'',
        position: 'absolute',
        left: 25,
        top: state.placement === 'top' ? 'auto' : '-30px',
        bottom: state.placement === 'top' ? '-30px' : 'auto',
        transform: state.placement === 'top' ? 'rotate(180deg)' : 'rotate(0deg)',
        border: '20px solid transparent',
        borderBottom: '20px solid #fff',
      },
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      background: isSelected ? '#334785' : '#fff',
      color: isSelected ? '#fff' : '#334785',
      overflow: 'hidden',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: (styles) => ({
      ...styles,
      color: '#fff',
      backgroundColor: '#6E7DB4',
      borderRadius: 5,
      position: 'relative',
      marginRight: 10,
    }),

    multiValueLabel: (styles) => ({
      ...styles,
      color: '#fff',
      margin: 0,
      padding: '0 6px',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      position: 'absolute',
      right: '-8px',
      top: '-6px',
      borderRadius: '100%',
      background: '#DCDCDC',
      color: '#6E7DB4',
      margin: 0,
      padding: 0,
      ':hover': {
        color: 'red',
      },
    }),
  },

  menuSelect: {
    container: (provided) => ({
      ...provided,
      position: 'relative',
    }),
    control: (styles, state) => ({
      ...styles,
      border: 'none',
      backgroundColor: 'none',
      margin: 0,
      width: '100%',
      borderColor: 'none',
      boxShadow: 'none',
      outline: 'none',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    valueContainer: (styles) => ({
      ...styles,
      height: '100%',
      margin: 0,
      padding: 0,
    }),
    singleValue: (styles) => ({
      ...styles,
      height: '100%',
      color: '#324797',
      display: 'flex',
      fontWeight: 500,
      alignItems: 'flex-end',
      alignContent: 'flex-end',
    }),
    placeholder: (styles) => ({
      ...styles,
      height: '100%',
      display: 'flex',
      fontWeight: 500,
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      whiteSpace: 'nowrap',
    }),
    menu: (styles) => ({
      ...styles,
      width: 311,
      padding: '15px 0 15px 0',
      margin: '19px 0 0 0',
      borderRadius: 15,
      boxShadow: '0px 0px 5px 1px rgba(56, 56, 56, 0.3);',
      '::after': {
        content: '\' \'',
        position: 'absolute',
        left: 25,
        top: -30,
        border: '20px solid transparent',
        borderBottom: '20px solid #fff',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (styles, { isSelected }) => ({
      margin: styles.margin,
      padding: styles.padding,
      fontSize: styles.fontSize,
      fontWeight: styles.fontWeight,
      background: isSelected ? '#334785' : '#fff',
      color: isSelected ? '#fff' : '#334785',
    }
    ),
  },
};

const MenuList = (props) => {
  const rows = props.children;
  const rowRenderer = ({
    key,
    index,
    style,
  }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );

  const rowHeight = 30;

  const customHeight = () => {
    // set the height to 250px or less if the list contains fewer lines
    return rows.length > 8 ? 250 : (rowHeight * rows.length);
  };

  return (
    <List
      style={{
        width: '100%',
        textAlign: 'start',
      }}
      width={500}
      height={props.selectProps.listHeight || customHeight()}
      rowHeight={rowHeight}
      rowCount={rows.length ? rows.length : 0}
      rowRenderer={rowRenderer}
    />
  );
};

const NoOptionsMessage = (props) => {
  return <components.NoOptionsMessage {...props} children={props.children} />;
};

export const SelectInput = (props: SelectProps) => {
  const {
    containerStyle = {},
    errorStyle = {},
    errorMsg,
    options,
    isFilter,
    menuBar,
    placeholder,
    label,
    isLoading,
    isDisabled,
    isReadOnly = false,
    isClearable = true,
    isMulti = false,
    defaultValue,
    value,
    defaultMenuIsOpen = false,
    menuPlacement = 'auto',
    menuIsOpen = undefined,
    onSelectClick = () => {},
    onAddTeamClick,
    onAddButtonFooterClick,
    addButtonFooterName,
    filterOption,
    inputRef,
    onBlur,
  } = props;

  return (
    <div className={cn(styles.selectWrapper)} style={containerStyle}>
      {label && <div className="form__input_label">{label}</div>}
      <Select
        {...props}
        ref={inputRef}
        onBlur={onBlur}
        blurInputOnSelect
        filterOption={filterOption || createFilter({ ignoreAccents: false, ignoreCase: true })}
        styles={isFilter ? mergeStyles(style.select, filterSelect) : menuBar ? style.menuSelect : style.select}
        aria-labelledby="aria-label"
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading}
        isClearable={(isReadOnly && isClearable) ? false : isClearable}
        isSearchable={!isReadOnly}
        menuIsOpen={isReadOnly ? false : menuIsOpen || undefined}
        isMulti={isMulti}
        defaultValue={defaultValue || null}
        // PastGames - edit game modal uses undefined as a value,
        // TODO: change to TSX component and use value typing
        value={options?.filter((i) => i.value).find((option) => option.value === value?.value) || value}
        placeholder={placeholder}
        options={options?.map((i) => {
          if (!i.value && !i.label) return { ...i, isDisabled: true };
          return i;
        })}
        // @ts-ignore
        onAddTeamClick={onAddTeamClick}
        addButtonFooterName={addButtonFooterName}
        onAddButtonFooterClick={onAddButtonFooterClick}
        components={{
          // MenuList,
          LoadingIndicator,
          Option,
          SingleValue,
          NoOptionsMessage,
          DropdownIndicator,
          Control: AddTeamButton,
          Menu: AddButtonFooter,
          GroupHeading,
        }}
        menuPosition="absolute"
        onMenuClose={() => onSelectClick(false)}
        defaultMenuIsOpen={options && options.length && defaultMenuIsOpen}
        menuPlacement={menuPlacement}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'rgba(48, 69, 148, 1)',
            primary: 'rgba(48, 69, 148, 0.7)',
          },
        })}
      />
      {errorMsg && (
        <span
          className={cn(styles.errorMessage, errorStyle)}
        >
          {errorMsg}
        </span>
      )}
    </div>
  );
};

// All option entity
const allOptions = {
  label: 'All teams',
  value: -1,
};

export const MultiSelect = (props) => {
  const {
    containerStyle,
    selectStyles,
    options,
    label,
    isLoading,
    menuIsOpen = undefined,
    isDisabled,
    isClearable = true,
    isReadOnly = false,
    isFilter = false,
    menuPlacement = 'auto',
    value,
    placeholder,
    errorMsg,
    errorStyle = {},
    name = '',
    isDropdownOpened = false,
    setDropdownOpen = () => {},
    schoolsInfoMsg = false,
    handleOpenMenu,
    inputRef,
  } = props;

  const Option = (props) => {
    const { isSelected } = props;
    return (
      <components.Option {...props}>
        <InputCheckbox value={isSelected} onClick={() => null} />
        <span title={props.children}>{props.children}</span>
      </components.Option>
    );
  };

  const MultiValue = (props) => {
    const {
      index,
      getValue,
    } = props;
    // Check if the value array include all option, minus 2 items as all option
    // isn't independ part of value
    const isAllOptionInclude = getValue()
      .find((v) => v.value === allOptions.value);
    const hiddenLength = getValue().length - 1;
    if (index < 1) {
      return (
        <components.MultiValue {...props}>
          <span className={styles.multiValue}>{props.children}</span>
        </components.MultiValue>
      );
    }
    if (index === 1) {
      return (
        <div
          className={cn(styles.multiValueCount, isFilter && styles.multiValueFilter)}
        >
          {isAllOptionInclude ? `(${hiddenLength})` : `+${hiddenLength}`}
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{
      ...containerStyle,
      position: 'relative',
    }}
    >
      {label && <div className="form__input_label">{label}</div>}
      <Select
        {...props}
        ref={inputRef}
        styles={mergeStyles(multiSelect(errorMsg), selectStyles)}
        name={name}
        isMulti
        menuIsOpen={menuIsOpen}
        isClearable={isClearable}
        isLoading={isLoading}
        isDisabled={isDisabled || isLoading || isReadOnly}
        width="311px"
        defaultValue={value || null}
        value={value || null}
        placeholder={placeholder}
        onMenuOpen={() => setDropdownOpen(true)}
        onMenuClose={() => setDropdownOpen(false)}
        handleOpenMenu={handleOpenMenu} // Uses in Control
        options={options}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        noOptionsMessage={NoOptionsMessage}
        components={{
          LoadingIndicator,
          MenuList,
          Option,
          NoOptionsMessage,
          MultiValue,
          MultiValueRemove,
          DropdownIndicator,
          IndicatorSeparator: null,
          Control: Control,
        }}
        menuPlacement={menuPlacement}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'rgba(48, 69, 148, 1)',
            primary: 'rgba(48, 69, 148, 0.7)',
          },
        })}
      />

      {
        (schoolsInfoMsg && isDropdownOpened) && (
          <div
            className={styles.schoolsInfo}
          >
            Schools are filtered by the state selected above
          </div>
        )
      }

      {errorMsg && (
        <span
          className={cn(styles.errorMessage, errorStyle)}
        >
          {errorMsg}
        </span>
      )}
    </div>
  );
};
