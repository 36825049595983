import { apiUrlV4, apiUrlV3 } from './api-versions';

const allowedURL = [
  `${apiUrlV4}pitcherOuting`,
  `${apiUrlV4}player`,
  `${apiUrlV4}school`,
  `${apiUrlV3}sport`,
  `${apiUrlV3}team_grades`,
];

export default allowedURL;
