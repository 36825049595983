import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import api from 'src/services/api';
import { store } from 'src/store/store';
import setAuthToken from 'src/util/setAuthToken';

// eslint-disable-next-line import/no-cycle
import ScorebirdService from './scorebird.service';

const sbs = new ScorebirdService();
const userObject = {
  fbUser: null,
  signInUser: null,
  userByEmail: null,
};

const dispatchCurrentUser = (user) => {
  localStorage.setItem('user', user.fbUser.email);
  store.dispatch({ type: 'currentUser/set', payload: user });
  store.dispatch({
    type: 'USER_SIGN_IN',
    currentUser: user.fbUser.email,
  });
  store.dispatch({
    type: 'USER_INFO',
    currentUserInfo: user.userByEmail,
  });
};

export const AuthenticateUser = (email) => sbs.getUserDataByEmail(email).then((res) => {
  if (res.success) {
    userObject.userByEmail = { ...res.data[0] };
  }
}).finally(() => {
  dispatchCurrentUser(userObject);
});

export const loginUser = (email, password) => {
  const auth = getAuth();
  const authParams = {
    email,
    password,
    // By default, pass customer, server will answer with correct user_type
    user_type: 'customer',
    offset: new Date().getTimezoneOffset(),
  };
  const promises = [
    signInWithEmailAndPassword(auth, email, password),
    sbs.signInNew(authParams),
    sbs.getUserDataByEmail(email),
  ];
  Promise.allSettled(promises).then((result) => {
    console.log(result);
    if (result[0].status === 'fulfilled') {
      const res = result[0].value;
      setAuthToken(res.user.accessToken);
      userObject.fbUser = res.user;
    } else {
      console.log(result[0].reason.code);
    }
    if (result[1].status === 'fulfilled') {
      const res = result[1].value;
      if (res.success) {
        userObject.signInUser = res.data;
      } else {
        throw new Error(res.message || 'Error on getting user data #117');
      }
    }
    if (result[2].status === 'fulfilled') {
      const res = result[2].value;
      if (res.success) {
        userObject.userByEmail = { ...res.data[0] };
      }
    }

    return userObject;
  }).then((user) => {
    dispatchCurrentUser(user);
  });
};

export const UserLogOut = () => {
  const auth = getAuth();
  const { SignOut } = api.authService();
  auth.signOut()
    .then(() => {
      const sessionId = localStorage.getItem('session_id');
      SignOut({ session_id: sessionId }).then();
    })
    .catch((error) => {
      snackbarService.error('Sign Out Error');
      console.error('Sign Out Error', error);
      throw error;
    })
    .finally(() => {
      const clearSessionStorage = () => {
        const sessionStorageKeys = Object.keys(sessionStorage);
        const importantKeys = ['skipWelcomeModal'];
        sessionStorageKeys.filter((key) => !importantKeys.includes(key)).forEach((key) => sessionStorage.removeItem(key));
      };

      clearSessionStorage();
      localStorage.clear();

      store.dispatch({ type: 'USER_LOGOUT' });
      store.dispatch({ type: 'currentUser/remove' });

      window.location.href = '/';
    });
};
