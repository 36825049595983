import { createAction, createReducer } from '@reduxjs/toolkit';

/* Initial State */
const initialState = {
  errorTrigger: () => {},
};

/* Actions */
const setErrorTrigger = createAction('setErrorTrigger', (trigger: () => void) => (
  { payload: trigger }
));

export const triggerErrorReducer = createReducer(initialState, (builder) => {
  builder.addCase(setErrorTrigger, (state, action) => {
    state.errorTrigger = action.payload;
  });
});
