import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { GetAllSportsRes } from 'src/services/interfaces/Sport.types';

import { apiUrlV4 } from '../constants/api-versions';
import { SportRequestEnums } from '../enums/SportRequestEnums';

export const SportService = (axios:AxiosInstance) => {
  /**
   * Get all sports
   * Save sports in REDUX store
   */

  const useGetAllSports = (param?: SportRequestEnums) => {
    const getAllSports = () => axios
      .get(`${apiUrlV4}sport${param || ''}`)
      .then((res) => {
        const resData: GetAllSportsRes = {
          data: res.data?.data.map((s) => ({ label: s.name, value: s._id })),
          detailedList: res.data?.data,
          success: res.data?.success,
        };

        return resData;
      });

    return useQuery(
      {
        queryKey: [param || 'query-get-sport'],
        queryFn: getAllSports,
        onError: (error) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error in fetching sport:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  return {
    useGetAllSports,
  };
};
