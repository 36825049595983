import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import { ErrorRes } from 'src/types';

import { NetworkSecurityType } from '../interfaces/Network.types';

export const NetworkService = (axios: AxiosInstance) => {
  const useGetNetworkSecurityType = () => {
    const networkSecurityType = () => axios
      .get(`${apiUrlV4}network-security-type`)
      .then((res) => {
        const options = res.data.data.map((type) => ({ value: type._id, label: type.name, comment: type.comment }));
        const resData: NetworkSecurityType = { data: options, success: res.data.success };

        return resData;
      });

    return useQuery({
      queryKey: ['query-network-security-type'],
      queryFn: networkSecurityType,
      refetchOnWindowFocus: false,
      onError: (error: ErrorRes) => {
        snackbarService.error(error.response.data.message);
        console.error(error.response.data.message);
      },
    });
  };

  return {
    useGetNetworkSecurityType,
  };
};
