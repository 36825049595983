import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';

export interface IOpponents {
  opponents: string[] | [];
}

export const SaveOpponents = (axios:AxiosInstance, schoolId:string) => {
  return useMutation(
    'mutation-opponents',
    (payload: IOpponents) => axios.put(
      `${apiUrlV4}schoolOpponent/${schoolId}`,
      { ...payload },
    ).then((res) => {
      return res;
    }).catch((err) => {
      console.error('Save opponents: ', err);
      snackbarService.error('Something went wrong with saving new opponent. Please try again later.');
    }),
  );
};

export const RemoveOpponents = (axios:AxiosInstance, schoolId:string) => {
  return useMutation(
    'mutation-opponents',
    (payload: IOpponents) => axios.delete(
      `${apiUrlV4}schoolOpponent/${schoolId}`,
      { data: payload },
    ).then((res) => {
      return res;
    }).catch((err) => {
      console.error('Remove opponents: ', err);
      snackbarService.error('Something went wrong with opponent removal. Please try again later.');
    }),
  );
};

export const GetOpponents = (axios:AxiosInstance, schoolId:string) => {
  return useQuery(
    'query-opponents',
    () => axios.get(
      `${apiUrlV4}schoolOpponent/${schoolId}`,
    ).then((res) => {
      return res.data;
    }).catch((err) => {
      console.error('Get opponents: ', err);
      snackbarService.error('We encountered an error while trying to get opponents. Please try again later.');
    }),
    { placeholderData: { data: { schoolOpponents: [] } } },
  );
};

export const GetRecentOpponents = (axios:AxiosInstance, schoolId:string) => {
  return useQuery(
    'query-recent-opponents',
    () => axios.get(`${apiUrlV4}schoolOpponent/recent/${schoolId}`).then((res) => {
      return res.data;
    }).catch((err) => {
      console.error(err);
      snackbarService.error('We encountered an error while trying to get recent opponents. Reload page and try again.');
    }),
    { refetchOnWindowFocus: false, cacheTime: 1000 * 60 * 60 },
  );
};
