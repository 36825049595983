import { useEffect, useState } from 'react';

import api from 'src/services/api';

import { SchoolsQuery } from '../interfaces/SchoolsQuery';

export const useFetchSchools = ({ query, addAllSchools = false }:SchoolsQuery) => {
  const [prevQuery, setPrevQuery] = useState(query);
  const [schools, setSchools] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const { GetSchools, GetSchoolsByEmail } = api.schoolsService();
  const { mutateAsync: getShortSchoolsData } = GetSchools();
  const { mutateAsync: getSchoolsByEmail } = GetSchoolsByEmail();

  const fetchSchools = (email?:string) => {
    setIsFetching(true);
    if (email) {
      getSchoolsByEmail(email)
        .then((res) => {
          setSchools(res.data.map((s) => ({
            label: s.name,
            value: s._id,
            country: s.country,
            state: s.state,
            associationId: s.association_id,
            association: s.association,
          })));
          setIsFetching(false);
        })
        .catch((err) => {
          setError(err);
          setIsFetching(false);
        });
    }
    if ((query?.country && query?.state) || query?.state || query?.association) {
      getShortSchoolsData({
        country: query?.country,
        state: query?.state,
        association: query?.association,
      })
        .then((res) => {
          if (res.success) {
            const schoolsArray = res.data;
            if (addAllSchools && schoolsArray.length > 0) {
              schoolsArray.unshift({
                label: 'All Schools',
                value: null,
              });
            }
            setSchools(schoolsArray);
          } else {
            setError('Error fetching schools.');
          }
        }).finally(() => {
          setIsFetching(false);
        });
    } else {
      setSchools([]);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (JSON.stringify(query) === JSON.stringify(prevQuery)) {
      return;
    }
    setPrevQuery(query);
    fetchSchools();

  }, [query?.country, query?.state, query?.association]);

  return {
    fetchSchools, schools, isFetching, error,
  };
};
