import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';

export const TwitterService = (axios: AxiosInstance) => {
  const TwitterAuthStatusCheck = () => {
    const twitterAuthStatusCheck = (payload) => axios
      .post(`${apiUrlV4}twitter/auth-status-check`, payload);

    return useMutation(
      'mutation-twitter-auth-status-check',
      twitterAuthStatusCheck,
      {
        onError: (error) => {
          console.error('Error in auth status check:', error);
          snackbarService.error('Something went wrong. Please, try later.');
        },
      },
    );
  };

  return {
    TwitterAuthStatusCheck,
  };
};
