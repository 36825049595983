/* eslint-disable */
const initialState = {
  detailed_list: [],
  last_fetched_date: new Date(),
};

const devices = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DETAILED_DEVICES_LIST':
      return { ...state, detailed_list: action.devices };
    case 'paginated_list':
      return { ...state, paginatedDevices: action.devices };

    default:
      return state;
  }
};

export default devices;
