/* eslint-disable */
const initialState = {
  detailed_List: [],
  last_fetched_date: new Date(),

};
const fanappusers = (state = initialState, action) => {
  switch (action.type) {
    case 'Fan_users_paginated':
      return { ...state, fanusers: action.fanusers };
    default:
      return state;
  }
};

export default fanappusers;
