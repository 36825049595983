import Slide from '@material-ui/core/Slide';
import { EventEmitter } from 'src/components/common/event-emitter';

class SnackbarService {
  eventEmitter = new EventEmitter();

  defaultOptions = {
    preventDuplicate: true,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    TransitionComponent: Slide,
  };

  open(message, options) {
    this.eventEmitter.emit({ message, options });
  }

  error(message, options = {}) {
    this.open(message, {
      variant: 'error',
      ...this.defaultOptions,
      ...options,
    });
  }

  warn(message, options = {}) {
    this.open(message, {
      variant: 'warning',
      ...this.defaultOptions,
      ...options,
    });
  }

  success(message, options = {}) {
    this.open(message, {
      variant: 'success',
      ...this.defaultOptions,
      ...options,
    });
  }
}

export const snackbarService = new SnackbarService();
