import { firebaseAuth } from 'src/fb-configuration/db-firebase';

export const getIdToken = async () => {
  try {
    const firebaseUser = await firebaseAuth.currentUser;
    if (firebaseUser !== null) {
      return await firebaseUser.getIdToken();
    }
  } catch (error) {
    console.error('Error retrieving Firebase ID token:', error);
  }
  return null;
};
