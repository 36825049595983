const phoneStyles = {
  dropdownStyle: {
    borderRadius: '15px',
  },
  containerStyle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Hind',
  },
  inputStyle: {
    border: 'none',
    fontSize: '16px',
    boxShadow: 'none',
    width: '100%',
  },
  buttonStyle: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '0',
  },
};

export default phoneStyles;
