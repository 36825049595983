import { useState, useEffect } from 'react';

import {
  adminMenu, associationAdminMenu, tappsAdminMenu,
} from './menu-settings';
import { useSuperAdminMenu } from './menu-settings/useSuperAdminMenu';

export const useGetAsideMenu = ({ association_admin, super_admin = false }) => {
  const [menu, setMenu] = useState([]);
  const { superAdminMenu } = useSuperAdminMenu();

  useEffect(() => {
    if (super_admin === true) {
      setMenu(superAdminMenu);
    } else if (super_admin === false) {
      if (association_admin) {
        if (association_admin === 'tapps') {
          setMenu(tappsAdminMenu);
        } else {
          setMenu(associationAdminMenu);
        }
      } else {
        setMenu(adminMenu);
      }
    } else {
      setMenu([]);
    }
  }, [super_admin, association_admin, superAdminMenu]);
  return menu;
};
