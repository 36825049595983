import { AxiosInstance } from 'axios';
import { SortOption } from 'src/components/customer/games-admin/v2/types';
import {
  ConnectionType,
  Game,
  GameCategory,
  GameData,
  GamesAdminData,
  SchoolData,
  DeviceData,
} from 'src/interfaces/GamesAdmin';

import { GameStatusType } from './FiltersService';
import { ApiResponse } from './types';

interface ApiCommonFilters {
  filters: {
    search?: string;
    sort_by: {
      quarter?: SortOption;
      time?: SortOption;
    };
  };
}

interface ApiGamesAdminFilters extends ApiCommonFilters {
  date: string;
  sport_list?: string[];
  grade_list?: string[];
  gender_id?: string[];
  shadow_list?: string[];
  connected_by_list?: string[];
  last_update_list?: string[];
  period_list?: number[];
  schedule_types?: string[];
}

interface ApiSchoolData {
  name: string;
  display_name: string;
  settings: {
    widget_sg_show_schedules: boolean;
    primary_color: string;
    secondary_color: string;
    logoURL: string;
    logo_url_small: string;
    Mascot: string;
  };
}

export interface ApiGame {
  _id: string;
  home_school_id: string;
  away_school_id: string;
  game_start_ts: string;
  name: string;
  home_name: string;
  sport: string;
  grade: string;
  level: string;
  gender: string;
  notes?: {
    game_admin?: string;
  };
  flagged: boolean;
  finalized: boolean;
  away_name: string;
  game_id: string;
  home_school_data?: ApiSchoolData;
  away_school_data?: ApiSchoolData;
  facility_data: {
    name: string;
    device_serial: string;
  };
  game_data: {
    state: {
      Away: string;
      Home: string;
      Quarter: string;
      Time: string;
      ts: string;
    };
    mapped_state: {
      Home: string;
      Away: string;
      game_time: string;
      no_auto_finalize: boolean;
    };
  };
  device_data: {
    serial: string;
    firmware_version: string;
    last_online_ts: string;
    online: boolean;
    device_info: {
      interface: string;
    };
    shadow: string;
    connection_type: ConnectionType;
    is_live: boolean;
  };
}

export interface ApiDeviceUpdateData {
  serial: string;
  firmware_version: string;
  last_online_ts: string;
  online: boolean;
  device_info: {
    interface: string;
  };
  shadow: string;
  connection_type: ConnectionType;
}

interface ApiGamesAdminData {
  flagged_schedules: ApiGame[];
  live_schedules: ApiGame[];
  upcoming_schedules: ApiGame[];
  final_schedules: ApiGame[];
  missed_schedules: ApiGame[];
}

const convertApiSchoolData = (apiSchoolData: ApiSchoolData): SchoolData => ({
  name: apiSchoolData.name,
  displayName: apiSchoolData.display_name,
  settings: apiSchoolData.settings
    ? {
      widgetSgShowSchedules: apiSchoolData.settings.widget_sg_show_schedules,
      primaryColor: apiSchoolData.settings.primary_color,
      secondaryColor: apiSchoolData.settings.secondary_color,
      logoURL: apiSchoolData.settings.logoURL,
      logoUrlSmall: apiSchoolData.settings.logo_url_small,
      mascot: apiSchoolData.settings.Mascot,
    }
    : null,
});

export const convertApiGameData = (
  apiGameData: ApiGame['game_data']
): GameData =>
  (apiGameData
    ? {
      state: {
        away: apiGameData.state.Away,
        home: apiGameData.state.Home,
        quarter: apiGameData.state.Quarter,
        time: apiGameData.state.Time,
        ts: apiGameData.state.ts,
      },
      mappedState: {
        away: apiGameData.mapped_state.Away,
        home: apiGameData.mapped_state.Home,
        gameTime: apiGameData.mapped_state.game_time,
      },
    }
    : null);

export const convertApiDeviceUpdateData = (
  apiDeviceData: ApiDeviceUpdateData
): DeviceData => ({
  serial: apiDeviceData.serial,
  firmwareVersion: apiDeviceData.firmware_version,
  lastOnlineTs: apiDeviceData.last_online_ts,
  online: apiDeviceData.online,
  deviceInfo: apiDeviceData.device_info
    ? {
      interface: apiDeviceData.device_info.interface,
    }
    : null,
  shadow: apiDeviceData.shadow,
  connectionType: apiDeviceData.connection_type,
  isLive: false,
});

export const convertApiDeviceData = (
  apiDeviceData: ApiGame['device_data']
): DeviceData =>
  (apiDeviceData
    ? {
      ...convertApiDeviceUpdateData(apiDeviceData),
      isLive: apiDeviceData.is_live,
    }
    : null);

const convertApiGame = (apiGame: ApiGame): Game => {
  const apiHomeSchoolData = apiGame.home_school_data;
  const apiAwaySchoolData = apiGame.away_school_data;
  return {
    id: apiGame._id,
    homeSchoolId: apiGame.home_school_id,
    awaySchoolId: apiGame.away_school_id,
    gameStartTs: apiGame.game_start_ts,
    gameId: apiGame.game_id,
    flagged: apiGame.flagged,
    finalized: apiGame.finalized,
    noAutoFinalize: apiGame?.game_data?.mapped_state?.no_auto_finalize,
    name: apiGame.name,
    homeName: apiGame.home_name,
    sport: apiGame.sport,
    grade: apiGame.level,
    gender: apiGame.gender,
    notes: apiGame.notes?.game_admin ?? '',
    awayName: apiGame.away_name,
    homeSchoolData: apiHomeSchoolData
      ? convertApiSchoolData(apiHomeSchoolData)
      : null,
    awaySchoolData: apiAwaySchoolData
      ? convertApiSchoolData(apiAwaySchoolData)
      : null,
    facilityData: apiGame.facility_data
      ? {
        name: apiGame.facility_data.name,
        deviceSerial: apiGame.facility_data.device_serial,
      }
      : null,
    gameData: convertApiGameData(apiGame.game_data),
    deviceData: convertApiDeviceData(apiGame.device_data),
  };
};

export interface GamesAdminFilters {
  date: Date;
  search: string;
  quarterSort: SortOption;
  timeSort: SortOption;
  genders: string[];
  levels: string[];
  sports: string[];
  sbConfig: string[];
  scoreboardTypes: string[];
  connectionTypes: string[];
  periodValues: string[];
  gameStatuses: GameStatusType[];
  lastUpdates: string[];
  school: string;
  state: string;
  association: string;
  country: string;
}

export const getDefaultGamesAdminFilters = (): GamesAdminFilters => ({
  date: new Date(),
  search: null,
  quarterSort: null,
  timeSort: null,
  genders: [],
  levels: [],
  sports: [],
  scoreboardTypes: [],
  sbConfig: [],
  connectionTypes: [],
  periodValues: [],
  gameStatuses: [GameStatusType.Live],
  lastUpdates: [],
  school: null,
  state: null,
  association: null,
  country: null,
});

export const convertFiltersToApi = (
  filters: Partial<GamesAdminFilters>
): ApiGamesAdminFilters => {
  const commonFilters = {
    ...(filters.search && { search: filters.search }),
    sort_by: {
      ...(filters.quarterSort && { quarter: filters.quarterSort }),
      ...(filters.timeSort && { time: filters.timeSort }),
    },
  };

  const apiFilters: ApiGamesAdminFilters = {
    date: filters.date.toISOString(),
    filters: commonFilters,
    ...Object.fromEntries(
      Object.entries({
        school_id: filters.school,
        state: filters.state,
        association_id: filters.association,
        country: filters.country,
        sport_list: filters.sports,
        grade_list: filters.levels,
        gender_id: filters.genders,
        shadow_list: filters.sbConfig,
        connected_by_list: filters.connectionTypes,
        last_update_list: filters.lastUpdates,
        period_list: filters.periodValues?.map(Number),
        schedule_types: filters.gameStatuses?.map((status) =>
          status.toLowerCase()),
      }).filter(([, value]) => value && value.length > 0)
    ),
  };
  return apiFilters;
};

export const GamesAdminService = (axios: AxiosInstance) => {
  const updateGameFlagged = async (
    id: string,
    userId: string,
    flagged: boolean
  ) => {
    if (flagged) {
      return axios.post('/v4/flaggedGame', {
        schedule_id: id,
        user_id: userId,
      });
    }
    return axios.delete('/v4/flaggedGame', {
      data: { schedule_id: id, user_id: userId },
    });
  };

  const updateGameNoAutoFinalize = async (
    id: string,
    userId: string,
    noAutoFinalize: boolean
  ) => {
    return axios.post('/v4/gamesAdmin/dontAutoFinalize', {
      game_id: id,
      user_id: userId,
      no_auto_finalize: noAutoFinalize,
    });
  };

  const nukeGame = async (userId: string, gameId: string) => {
    return axios.post('/v4/gamesAdmin/nukeGame', {
      user_id: userId,
      game_id: gameId,
    });
  };

  const updateGameNotes = async (id: string, notes: string, userId: string) => {
    return axios.put('/v4/gamesAdmin/updateScheduleNotes', {
      schedule_id: id,
      notes,
      user_id: userId,
    });
  };

  const updateGameScore = async (
    id: string,
    home: number,
    away: number,
    userId: string
  ) => {
    return axios.post('/v4/game/save-scores', {
      schedule_id: id,
      Home: home,
      Away: away,
      user_id: userId,
    });
  };

  const finalizeGame = async (
    id: string,
    score: { home: number; away: number },
    gameId: string,
    userId: string
  ) => {
    return axios.post('/v4/game/confirm-and-finalize', {
      schedule_id: id,
      game_id: gameId,
      Home: score.home,
      Away: score.away,
      user_id: userId,
    });
  };

  const rebootDevice = async (serial: string) => {
    return axios.put(`/v4/device/${serial}/reboot`, { serial });
  };

  const convertApiGamesAdminData = (
    apiGamesAdminData: ApiGamesAdminData
  ): GamesAdminData => {
    return {
      [GameCategory.Flagged]:
        apiGamesAdminData.flagged_schedules?.map(convertApiGame) ?? [],
      [GameCategory.Live]:
        apiGamesAdminData.live_schedules?.map(convertApiGame) ?? [],
      [GameCategory.Upcoming]:
        apiGamesAdminData.upcoming_schedules?.map(convertApiGame) ?? [],
      [GameCategory.Final]:
        apiGamesAdminData.final_schedules?.map(convertApiGame) ?? [],
      [GameCategory.Missed]:
        apiGamesAdminData.missed_schedules?.map(convertApiGame) ?? [],
    };
  };

  const getAllGamesData = async (
    filters: GamesAdminFilters
  ): Promise<GamesAdminData> => {
    try {
      const apiFilters = convertFiltersToApi(filters);
      const res = await axios.post<ApiResponse<ApiGamesAdminData>>(
        '/v4/gamesAdmin/games',
        apiFilters
      );
      const apiGamesAdminData = res.data.data;
      return convertApiGamesAdminData(apiGamesAdminData);
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  };

  const getGamesWithNotes = async (
    filters: GamesAdminFilters
  ): Promise<GamesAdminData> => {
    try {
      const apiFilters = convertFiltersToApi(filters);
      const res = await axios.post<ApiResponse<ApiGamesAdminData>>(
        '/v4/gamesAdmin/games/withNotes',
        apiFilters
      );
      const apiGamesAdminData = res.data.data;
      return convertApiGamesAdminData(apiGamesAdminData);
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  };

  const getFinalizedGames = async ({
    timeSort,
    quarterSort,
    gameStatuses,
    ...filters
  }: GamesAdminFilters): Promise<Game[]> => {
    try {
      const apiFilters = convertFiltersToApi(filters);
      const res = await axios.post<ApiResponse<ApiGame[]>>(
        '/v4/gamesAdmin/games/finalized',
        apiFilters
      );
      const apiGamesAdminData = res.data.data;
      return apiGamesAdminData.map(convertApiGame);
    } catch (e) {
      console.log(e);
      throw new Error(e);
    }
  };

  return {
    getAllGamesData,
    updateGameFlagged,
    convertFiltersToApi,
    nukeGame,
    updateGameNotes,
    updateGameNoAutoFinalize,
    updateGameScore,
    finalizeGame,
    rebootDevice,
    getFinalizedGames,
    getGamesWithNotes,
  };
};
