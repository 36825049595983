const yearMonthDay: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const getCSTTimeZoneFormat = (date: Date) => {
  return `${date
    .toLocaleString('en-US', yearMonthDay)} ${new Date(date)
    .toLocaleTimeString('en-US', { hour12: true })
    .replace(/:\d+\s/, ' ')}
    `;
};

export default getCSTTimeZoneFormat;
