import React, { useMemo } from 'react';

import useSocket from 'src/hooks/useSocket';

export const SocketContext = React.createContext(null);

export const SocketProvider = ({ children }) => {
  const socket = useSocket();
  const memoSocket = useMemo(() => socket, [socket]);

  return (
    <SocketContext.Provider value={memoSocket}>{children}</SocketContext.Provider>
  );
};
