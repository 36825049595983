import React from 'react';

import { components, ControlProps } from 'react-select';

import Columns from './components/columns/Columns';

type TControlProps = ControlProps & {
  selectProps: { handleOpenMenu: () => void },
  menuIsOpen: boolean,
};

const Control = ({
  children, selectProps, menuIsOpen, ...props
}: TControlProps) => {
  const { handleOpenMenu } = selectProps;

  if (!handleOpenMenu) {
    return (
      // @ts-ignore
      <components.Control {...props}>
        {children}
      </components.Control>
    );
  }

  return (
    <Columns isMenuOpen={menuIsOpen} handleOpenMenu={handleOpenMenu} />
  );
};

export default Control;
