import { array, object, string } from 'yup';

export const schoolsSchema = array()
  .of(
    object()
      .shape({
        label: string().required('Label is required'),
        value: string().required('Value is required'),
      }),
  )
  .nullable() // for handling null value when clearing options via clicking "x"
  .min(1, 'At least one school is required')
  .max(10, 'At least one, maximum 10 schools')
  .required('Please fill out this field');
