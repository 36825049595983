import { postalCodeByCountry } from 'src/constants/postalCodeByCountry';
import { string } from 'yup';

export const postalCodeSchema = string()
  .when('postalCode', { // validation depends on this field or another
    is: (postalCodeValue) => {
      return !!postalCodeValue;
    },
    // eslint-disable-next-line func-names
    then: string().test('country-code-match', 'Invalid postal code', function (value) {
      const countryValue: string = this.parent?.country?.value;
      return postalCodeByCountry[countryValue]?.test(value);
    }),
  });
