/* eslint-disable */
const initialState = {
  list: [],
  detailed_list: [],
  last_fetched_date: new Date(),
};

const schools = (state = initialState, action) => {
  switch (action.type) {
    case 'set_schools_list':
      return { ...state, list: action.schools };
    case 'School_Id':
      return {
        ...state,
        school_id: action.school_id,
        schoolData: action.schoolData,
        schoolState: action.schoolState,
        organisation_mongo_id: action.organisation_mongo_id,
      };
    default:
      return state;
  }
};

export default schools;
