import React from 'react';

import { GroupHeadingProps } from 'react-select';

import styles from '../SelectAsyncPaginate.module.css';

const GroupHeading = (props: GroupHeadingProps) => {
  const { children } = props;

  return (
    <div className={styles.groupWrapper}>
      <div className={styles.groupHeading}>{children}</div>
    </div>
  );
};

export default GroupHeading;
