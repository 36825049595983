import { mixed, object, string } from 'yup';

export const organizationTypeSchema = mixed().when('organizationType', {
  is: (value) => typeof value === 'object',
  then: object().shape({
    label: string().required('Label is required'),
    value: string().required('Value is required'),
  })
    .nullable()
    .required('Please fill out this field'),
  otherwise: string()
    .max(100, 'No more than 100 characters')
    .test('no-spaces', 'Please fill out this field', (value) => value?.trim() !== '')
    .required('Please fill out this field'),
});
