/* eslint-disable */
const initialState = {
  personal_info: null,
  facilities: [],
  promo_code: null,
  promo_code_valid: null,
  sel_found_us: null,
  billing_address: null,
  shipping_address: null,
  same_addresses: true,
  pay_via: null,
  existing_school: null, /* If the person is an existing user, we query their school info on the personal-info page.  Keep it around for pre-populating the address step */
  stripe_token: null,
  nest_cost: 400.00,
  curr_step: '/onboard',
  max_step: '/onboard', // this is the maximum step that they have reached in the flow
  steps: [
    { label: 'Start', path: 'onboard' },
    { label: 'Personal Info', path: 'personal-info' },
    { label: 'Facilities', path: 'facilities' },
    { label: 'Address', path: 'address' },
    { label: 'Payment Method', path: 'payment-method' },
    { label: 'Confirmation', path: 'confirmation' },
  ],
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case 'PERSONAL_INFO':
      return { ...state, personal_info: action.info };

    case 'EXISTING_SCHOOL':
      return { ...state, existing_school: action.existing_school };

    case 'ADDRESS':
      return {
        ...state, billing_address: action.billing_address, shipping_address: action.shipping_address, same_addresses: action.same_addresses,
      };

    case 'PAY_VIA':
      return { ...state, pay_via: action.pay_via };

    case 'STRIPE_TOKEN':
      return { ...state, stripe_token: action.token };

    case 'FACILITIES':
      return {
        ...state,
        facilities: action.facilities,
        promo_code: action.promo_code,
        promo_code_valid: action.promo_code_valid,
        sel_found_us: action.sel_found_us,
        nest_cost: action.nest_cost,
      };

    case 'CURR_STEP':
      return { ...state, curr_step: action.step };

    case 'MAX_STEP_REACHED':
      return { ...state, max_step: action.step };

    case 'RESET_ONBOARDING':
      return initialState;

    default:
      return state;
  }
};

export default onboarding;
