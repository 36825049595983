/* eslint-disable */
import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';

import { snackbarService } from 'src/components/common/snackbar/snackbar-service';

export const Snackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const subscription = snackbarService.eventEmitter.subscribe(({ message, options }) => {
      enqueueSnackbar(message, options);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
  return <></>;
};
