import React from 'react';

import { useNavigate } from 'react-router-dom';
import CloseIcon from 'src/assets/images/closeNew.svg';
import sadIcon from 'src/assets/images/modal/sad-icon.svg';
import Button from 'src/components/common/button/CustomButton';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';

import styles from './ErrorModal.module.css';

interface ErrorModalProps {
  setOpenDeniedModal: (open: boolean) => void;
  contactSupportFn: () => void;
}

const ErrorModalNew = (props: ErrorModalProps) => {
  const { setOpenDeniedModal, contactSupportFn } = props;

  const navigate = useNavigate();

  const handleResendRequest = () => {
    setOpenDeniedModal(false);
    navigate('/resend-request');
  };

  const handleCloseModal = () => {
    setOpenDeniedModal(false);
  };

  return (
    <ModalWindow isOpen className={styles.modal}>
      <Button version="icon" onClick={handleCloseModal} className={styles.closeIcon}>
        <img
          src={CloseIcon}
          alt="close"
        />
      </Button>
      <div className={styles.container}>
        <img src={sadIcon} className={styles.typeIcon} alt="type-icon" />
        <div className={styles.header}>
          <p className={styles.title}>Your request to create an account <br /> was denied.</p>
          <p className={styles.text}>If you think that this was a mistake, please resend your access request or contact support.</p>
        </div>

        <div className={styles.actions}>
          <Button className={styles.resendBtn} onClick={handleResendRequest}>Resend Request</Button>
          <Button className={styles.contactBtn} onClick={contactSupportFn}>Contact Support</Button>
        </div>
      </div>

    </ModalWindow>
  );
};

export default ErrorModalNew;
