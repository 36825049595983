const getWeekdayMonthDay = (date: Date) => {
  const inputDate = new Date(date);

  const day = inputDate.toLocaleString('en-US', { day: 'numeric' });
  const month = inputDate.toLocaleString('en-US', { month: 'short' });
  const weekday = inputDate.toLocaleString('en-US', { weekday: 'short' });
  const time = inputDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  return `${weekday}, ${month} ${day} ${time}`;
};

export default getWeekdayMonthDay;
