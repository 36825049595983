import React, { lazy } from 'react';

import CustomerLayout from 'src/components/customer/CustomerLayout';

import { Route } from '../ScoreBirdRouter.types';

const UserManagement = lazy(() => import('src/components/customer/user-management/UserManagement'));
const NotFound = lazy(() => import('src/components/common/other-pages/NotFound'));
const Profile = lazy(() => import('src/components/customer/profile/Profile'));
const GameDay = lazy(() => import('src/components/customer/game-day/GameDay'));
const AboutFacility = lazy(() => import('src/components/customer/facilities/aboutFacility/AboutFacility'));
const SchoolForm = lazy(() => import('src/components/customer/schools/components/schoolForm/SchoolForm'));
const RequestSchool = lazy(() => import('src/components/customer/schools/components/requestSchool/RequestSchool'));
const SchoolDetails = lazy(() => import('src/components/customer/schools/components/details-components/SchoolDetails'));
const AboutTeam = lazy(() => import('src/components/customer/teams/components/aboutTeam/AboutTeam'));
const AddUserModal = lazy(() => import('src/components/customer/users/add-user/AddUserModal'));
const Schedules = lazy(() => import('src/components/customer/schedules/Schedules'));
const RegularScheduleForm = lazy(() => import('src/components/customer/schedules/components/regular-schedules/regular-schedule-form/RegularScheduleForm'));
const Facilities = lazy(() => import('src/components/customer/facilities/Facilities'));
const Devices = lazy(() => import('src/components/customer/devices/Devices'));
const DeviceTabs = lazy(() => import('src/components/customer/devices/components/tabs/Tabs'));
const DeviceDetails = lazy(() => import('src/components/customer/devices/components/tabs/components/details/Details'));
const DeviceWifiSetup = lazy(() => import('src/components/customer/devices/components/tabs/components/wifiSetup/WifiSetup'));
const DeviceScoreboard = lazy(() => import('src/components/customer/devices/components/tabs/components/scoreboard/Scoreboard'));
const DeviceNativeVideo = lazy(() => import('src/components/customer/devices/components/tabs/components/nativeVideo/NativeVideo'));
const GamesAdmin = lazy(() => import('src/components/customer/games-admin/GamesAdmin'));
const GamesAdminV2 = lazy(() => import('src/components/customer/games-admin/v2/GamesAdmin'));
const Schools = lazy(() => import('src/components/customer/schools/Schools'));
const Teams = lazy(() => import('src/components/customer/teams/Teams'));
const OverlayBuilder = lazy(() => import('src/components/customer/overlay-builder/OverlayBuilder'));
const OverlayBuilderSecondStep = lazy(() => import('src/components/customer/overlay-builder/components/overlayBuilderSecondStep/OverlayBuilderSecondStep'));
const Twitter = lazy(() => import('src/components/customer/twitter/twitter'));
const TwitterIndex = lazy(() => import('src/components/customer/twitter'));
const PitchCount = lazy(() => import('src/components/customer/pitch-count/PitchCount'));
// const PastGames = lazy(() => import('src/components/customer/games/Games'));
const Users = lazy(() => import('src/components/customer/users/Users'));
const Notification = lazy(() => import('src/components/customer/notification/Notification'));

const superAdminRoutes: Route[] = [
  {
    element: <CustomerLayout />,
    path: '/',
    children: [
      {
        index: true,
        element: <GameDay />,
      },
      {
        path: 'schedules',
        element: <Schedules />,
      },
      {
        path: 'schedules/add',
        element: <RegularScheduleForm />,
      },
      {
        path: 'schedules/:scheduleId',
        element: <RegularScheduleForm />,
      },
      {
        path: 'facilities',
        element: <Facilities />,
      },
      {
        path: 'facilities/:facilityId',
        element: <AboutFacility />,
      },
      {
        path: 'schools',
        element: <Schools />,
      },
      {
        path: 'schools/add',
        element: <SchoolForm />,
      },
      {
        path: 'schools/:schoolId/edit',
        element: <SchoolForm />,
      },
      {
        path: 'schools/:schoolId',
        element: <SchoolDetails />,
      },
      {
        path: 'schools/request',
        element: <RequestSchool />,
      },
      {
        path: 'schools/:schoolId',
        element: <SchoolDetails />,
      },
      {
        path: 'teams',
        element: <Teams />,
      },
      {
        path: 'teams/:teamId',
        element: <AboutTeam />,
      },
      {
        path: 'devices',
        element: <Devices />,
      },
      {
        path: 'devices/:deviceId',
        element: <DeviceTabs />,
        children: [
          {
            path: 'details',
            element: <DeviceDetails />,
          },
          {
            path: 'wifi-setup',
            element: <DeviceWifiSetup />,
          },
          {
            path: 'scoreboard',
            element: <DeviceScoreboard />,
          },
          {
            path: 'native-video',
            element: <DeviceNativeVideo />,
          },
        ],
      },
      {
        path: 'pitch-count/*',
        element: <PitchCount />,
      },
      {
        path: 'overlay-builder',
        element: <OverlayBuilder />,
      },
      {
        path: 'overlay-builder/preview',
        element: <OverlayBuilderSecondStep />,
      },
      {
        path: 'tweetScores',
        element: <Twitter />,
      },
      {
        path: 'twitter/:authStatus/:message/:error',
        element: <TwitterIndex />,
      },
      // {
      //   path: 'games',
      //   element: <PastGames />,
      // },
      // {
      //   path: 'games/:scheduleId',
      //   element: <EditGameModal />,
      // },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/:id',
        element: <Profile editProfile />,
      },
      {
        path: 'user-management',
        element: <UserManagement />,
      },
      {
        path: 'games-admin',
        element: <GamesAdmin />,
      },
      {
        path: 'games-admin-v2',
        element: <GamesAdminV2 />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'invite-user',
        element: <AddUserModal />,
      },
      {
        path: 'add-user',
        element: <AddUserModal />,
      },
      {
        path: 'notifications',
        element: <Notification />,
      },
      {
        path: '*' || 'not-found',
        element: <NotFound />,
      },
    ],
    protected: true,
  },
];

export default superAdminRoutes;
