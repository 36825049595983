/* eslint-disable */
const initialState = {
  isEdit: false,
  detailed_list: null,
  last_fetched_date: new Date(),

};

const facilities = (state = initialState, action) => {
  switch (action.type) {
    case 'TOGGLE_BUTTON':
      return { ...state, isEdit: !state.isEdit };
    case 'FACILITY_TYPES':
      return { ...state, facilitytypes: action.facilitytypes };
    case 'SCOREBOARD_TYPES':
      return { ...state, scoreboardtypes: action.scoreboardtypes };
    case 'FACILITIES':
      return {
        ...state,
        facilities: action.facilities,
        allfacilities: action.allfacilities,
      };
    case 'SET_DETAILED_ALLFACILITIES_LIST':
      return { ...state, detailed_list: action.facilities };

    default:
      return state;
  }
};

export default facilities;

// const initialState1= {
//   list: []
// }

// const schools = (state = initialState1, action) => {
//   switch (action.type) {
//     case 'ALL_FACILITIES':
//       return Object.assign({}, state, {
//         list: action.facilities
//       });
//     default:
//        return state;
//   }
// };

// export default facilities;

// case 'TOGGLE_CONTAINER_MENU':
