import React from 'react';

import Illustration from 'src/assets/images/404-page/illustrarion.png';
import Button from 'src/components/common/button/CustomButton';
import { ContactEnums } from 'src/enums/ContactEnums';

import styles from './PageCrash.module.css';

const { EMAIL } = ContactEnums;

const PageCrash = () => {
  return (
    <div className={styles.wrapper}>
      <h2>Oops!</h2><h3> Page crashed unexpectedly</h3>
      <p>Sorry, the page you’re trying to access crashed. We did not expected this behavior. </p>
      <p>Please report this behavior to our support team to let us fix this problem for you as fast as we can! Thank you!</p>
      <p>Email: <a href={`mailto:${EMAIL}?subject=Unexpected web-page crush`}>{EMAIL}</a></p>
      <Button version="secondary" onClick={() => window.location.reload()}>Reload page now</Button>
      <img className={styles.illustration} src={Illustration} alt="error-404" />
    </div>
  );
};

export default PageCrash;
