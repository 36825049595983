import { messages } from 'src/constants';
import { object, string } from 'yup';

const { FILL_OUT_FIELD } = messages;

export const selectDefaultSchema = ({ msg = FILL_OUT_FIELD, isRequired = true } = {}) => {

  const baseSchema = object().shape({
    label: string(),
    value: string(),
  })
    .nullable() // for handling null value when clearing options via clicking "x"
    .required(msg);

  return isRequired ? baseSchema.required(msg) : baseSchema.notRequired();
};
