import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import api from 'src/services/api';
import { capitalize } from 'src/util/stringFormat';

export const useFetchGenders = () => {
  const dispatch = useDispatch();
  const { GetGenders } = api.genderService();
  const { data, isLoading } = GetGenders();

  useEffect(() => {
    if (data) {
      const transformedArray = data?.data.data.map((gender) => {
        return {
          value: gender._id,
          label: capitalize(gender.name),
        };
      });

      dispatch({
        type: 'genders/setGenders',
        payload: transformedArray,
      });
    }
  }, [data]);

  return {
    data,
    isLoading,
  };
};
