export class EventEmitter {
  _subscribers = [];

  subscribe(fn) {
    this._subscribers.push(fn);
    return { unsubscribe: () => this._unsubscribe(fn) };
  }

  _unsubscribe(fn) {
    this._subscribers = this._subscribers.filter((sub) => sub !== fn);
  }

  emit(data) {
    this._subscribers.forEach((fn) => fn(data));
  }
}
