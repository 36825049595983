const cap = (input) => {
  const str = input.trim().split(' ');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    if (str[i]) {
      str[i] = str[i][0].toUpperCase() + str[i].substring(1);
    }
  }
  return str.join(' ');
};

export const capitalize = (input) => (!input ? '' : input[0].toUpperCase() + input.slice(1));

export const capitalizeAllFirstLetters = (input) => (!input ? '' : cap(input));
