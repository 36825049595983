/* eslint-disable */
const initialState = {
  isCustomer: !(localStorage.getItem('isCustomer') === 'false' || localStorage.getItem('isCustomer') === null),
};

const isCustomer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMER':
      return { ...state, isCustomer: action.isCustomer };
    default:
      return state;
  }
};

export default isCustomer;
