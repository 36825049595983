import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Close from 'src/assets/images/close.svg';
import Button from 'src/components/common/button/CustomButton';
import { Input } from 'src/components/common/input/Input';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { firebaseAuth } from 'src/fb-configuration/db-firebase';
import api from 'src/services/api';
import ScorebirdService from 'src/services/scorebird.service';
import { RootState } from 'src/store/types/rootStateTypes';

import styles from './ChangePasswordModal.module.css';
import { ChangePasswordModalProps } from './ChangePasswordModal.props';
import { userSchema, profileSchema } from './changePasswordModalSchema';

const ChangePasswordModal = ({ user, onClose, isOpen }: ChangePasswordModalProps) => {
  const sbs = new ScorebirdService();
  const { ChangePassword } = api.authService();
  const { mutateAsync: changePassword, isLoading: changePasswordLoading } = ChangePassword();
  const currentUser = useSelector((state: RootState) => state.currentUser.currentUser);
  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(currentUser?.email === user?.email ? profileSchema : userSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const processForm = (form: any) => {

    const params = {
      email: user.email,
      password: form.password,
      new_password: form.newPassword,
    };

    const paramsByAdmin = {
      email: user.email,
      oldPassword: form.password,
      newpassword: form.newPassword,
    };

    if (user?.email === currentUser?.email) {
      changePassword(params).then((res) => {
        if (res.success) {
          if (currentUser?.email === user?.email) {
            signInWithEmailAndPassword(firebaseAuth, currentUser?.email, form.newPassword).then();
          }
          snackbarService.success('Password changed successfully');
          reset();
          onClose();
        }
      })
        .catch((err) => {
          snackbarService.error(err.response.data.message);
          console.error('Error when try to change password:', err);
          throw err;
        });
    } else {
      sbs.changeUserPasswordByAdmin(paramsByAdmin).then((res) => {
        if (res.success) {
          snackbarService.success('Password changed successfully');
          reset();
          onClose();
        } else {
          snackbarService.error(res.message || 'Password change failed');
        }
      });
    }
  };
  return (
    <ModalWindow isOpen={isOpen} className={styles.modal}>
      <Button version="icon" className={styles.close} onClick={onClose}><img src={Close} alt="X" /></Button>
      <div className={styles.wrapper}>
        <h4 className={styles.heading}>Change Password</h4>
        <form className={styles.container}>
          {currentUser?.email === user?.email ? (
            <Controller
              name="password"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  type="password"
                  version="password"
                  value={value}
                  onChange={onChange}
                  label="Current Password"
                  errorMsg={errors.password && errors.password.message}
                />
              )}
            />
          ) : null}
          <Controller
            name="newPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                type="password"
                version="password"
                value={value}
                onChange={onChange}
                label="New Password"
                errorMsg={errors.newPassword && errors.newPassword.message}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                type="password"
                version="password"
                value={value}
                onChange={onChange}
                label="Confirm New Password"
                errorMsg={errors.confirmPassword && errors.confirmPassword.message}
              />
            )}
          />
          <div className={styles.actions}>
            <Button className={styles.saveBtn} disabled={changePasswordLoading} onClick={handleSubmit(processForm)}>Save</Button>
          </div>
        </form>
      </div>
    </ModalWindow>
  );
};

export default ChangePasswordModal;
