import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import {
  SignUpType, ISignIn, IForgotPassword, IChangePassword, IValidateLink, IResetPassword, IResendRequest,
} from 'src/services/interfaces/AuthService.interface';

export const AuthService = (axios: AxiosInstance) => {

  const SignUp = () => {
    return useMutation(
      'mutation-sign-up',
      (payload: SignUpType) => axios
        .post(`${apiUrlV4}auth/sign-up`, payload)
        .then((res) => {
          return res;
        }),
    );
  };

  const SignUpFanAsAdmin = () => {
    return useMutation(
      'mutation-sign-up-fan-as-admin',
      (payload: SignUpType) => axios
        .post(`${apiUrlV4}auth/sign-up-fan-as-admin`, payload)
        .then((res) => {
          return res;
        }),
    );
  };

  const SignIn = () => {
    return useMutation(
      'mutation-sign-in',
      (payload: ISignIn) => axios
        .post(`${apiUrlV4}auth/sign-in`, payload)
        .then((res) => {
          return res;
        }),
    );
  };

  const SignOut = (payload) => {
    return axios
      .post(`${apiUrlV4}auth/sign-out`, payload)
      .then((res) => {
        return res;
      });
  };

  const ForgotPassword = () => {
    return useMutation(
      'mutation-forgot-password',
      (payload: IForgotPassword) => axios
        .post(`${apiUrlV4}auth/forgot-password`, payload)
        .then((res) => {
          return res.data;
        }),
    );
  };

  const ChangePassword = () => {
    return useMutation(
      'mutation-change-password',
      (payload: IChangePassword) => axios
        .put(`${apiUrlV4}auth/change-password`, payload)
        .then((res) => {
          return res.data;
        }),
    );
  };

  const ValidateLink = () => {
    return useMutation(
      'mutation-validate-link',
      (payload: IValidateLink) => axios
        .post(`${apiUrlV4}auth/validate-link`, payload)
        .then((res) => {
          return res;
        }),
    );
  };

  const ResetPassword = () => {
    return useMutation(
      'mutation-reset-password',
      (payload: IResetPassword) => axios
        .put(`${apiUrlV4}auth/reset-password`, payload)
        .then((res) => {
          return res;
        }),
    );
  };

  const ResendRequest = () => {
    const resendRequest = (payload: IResendRequest) => axios.post(`${apiUrlV4}auth/join-request`, payload);

    return useMutation(
      resendRequest,
      {
        onSuccess: () => {
          snackbarService.success('Resend request sent successfully');
        },
        onError: (error) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error resend request:', error);
        },
      },
    );
  };

  const ValidateHuman = () => {
    const validateHuman = (token: string) => axios.post(`${apiUrlV4}auth/validate-human`, { token });
    return useMutation(
      validateHuman,
      {
        onError: (error) => {
          snackbarService.error('Captcha is invalid.');
          console.error('Error in captcha:', error);
        },
      },
    );
  };

  return {
    SignUp,
    SignUpFanAsAdmin,
    SignIn,
    SignOut,
    ForgotPassword,
    ChangePassword,
    ValidateLink,
    ResetPassword,
    ResendRequest,
    ValidateHuman,
  };
};
