interface IRole {
  label: string;
  value: string;
}

export const sortRoles = (roles: IRole[]) => {
  return roles.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};
