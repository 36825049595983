import { AxiosInstance } from 'axios';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';

import { apiUrlV4 } from '../../constants/api-versions';
import {
  GameAdminLiveQuery,
  GameAdminFinalizedQuery,
  GameUpdateQuery,
  GameUpdateNotesQuery,
  GameAdminDevicesOnlineWithNoGameQuery,
  GameAdminRecentSchedulesQuery,
  GameAdminDevicesOnlineWithGame,
  GameAdminGamesWithNotesQuery,
} from '../interfaces';

export const GameAdminService = (axios: AxiosInstance) => {
  /**
   * Request to get live games
   * @param query
   * @returns array of live schedules
   */
  const getLiveSchedules = (query: GameAdminLiveQuery) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/getLiveSchedules`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Can\'t get live games. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in getLiveSchedules:', error);
        snackbarService.error('Can\'t get live games. Please, try later.');
        throw error;
      });
  };

  /**
   * Request to get today's finalized games
   * @param query
   * @returns array of finalized schedules
   */
  const getFinalizedSchedules = (query: GameAdminFinalizedQuery) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/getFinalizedSchedules`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Can\'t get finalized games. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get getFinalizedSchedules:', error);
        snackbarService.error('Can\'t get finalized games. Please, try later.');
        throw error;
      });
  };

  /**
   * Get online devices with no game
   * @param query
   * @returns array of devices
   */
  const getDevicesOnlineWithNoGame = (query: GameAdminDevicesOnlineWithNoGameQuery) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/getDevicesOnlineWithNoGame`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Can\'t get devices. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in get getDevicesOnlineWithNoGame:', error);
        snackbarService.error('Can\'t get online devices. Please, try later.');
        throw error;
      });
  };

  /**
   * Sets the game.state to not auto-finalize
   * @param query
   * @returns The api response
   */
  const setDontAutoFinalize = (query: GameUpdateQuery) => {
    return axios
      .put(`${apiUrlV4}gamesAdmin/setDontAutoFinalize`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Can\'t update game. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        console.error('Error in setDontAutoFinalize:', error);
        snackbarService.error('Can\'t set don\'t auto finalize. Please, try later.');
        throw error;
      });
  };

  /**
   * Nukes a game
   * @param query
   * @returns The api response
   */
  const nukeGame = (query: GameUpdateQuery) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/nukeGame`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Problem nuking game. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Can\'t nuke game. Please, try later.');
        throw error;
      });
  };

  /**
   * Update the schedule.notes.game_admin field
   * @param query
   * @returns The api response
   */
  const updateScheduleNotes = (query: GameUpdateNotesQuery) => {
    return axios
      .put(`${apiUrlV4}gamesAdmin/updateScheduleNotes`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Problem updating notes. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Can\'t update notes. Please, try later.');
        throw error;
      });
  };

  /**
   * Get recent schedules for the given school_id
   * @param query
   * @returns The api response
   */
  const getRecentSchoolSchedules = (query: GameAdminRecentSchedulesQuery) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/getRecentSchoolSchedules`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Problem getting schedules. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Can\'t get schedules. Please, try later.');
        throw error;
      });
  };

  /**
   * Get games with notes
   * @param query
   * @returns The api response
   */
  const getGamesWithNotes = (query: GameAdminGamesWithNotesQuery) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/getGamesWithNotes`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Problem getting schedules with notes. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Can\'t get schedules with notes. Please, try later.');
        throw error;
      });
  };

  /**
   * Get online devices with game latch status
   * @param query
   * @returns The api response
   */
  const getDevicesOnlineWithGame = (query: GameAdminDevicesOnlineWithGame) => {
    return axios
      .post(`${apiUrlV4}gamesAdmin/getDevicesOnlineWithGame`, query)
      .then((res: any) => {
        if (!res.data.success) {
          snackbarService.error('Problem getting devices with game. Please, try later.');
          throw res.data.message;
        }
        return res.data;
      })
      .catch((error) => {
        snackbarService.error('Can\'t get devices with game. Please, try later.');
        throw error;
      });
  };

  return {
    getLiveSchedules,
    nukeGame,
    setDontAutoFinalize,
    updateScheduleNotes,
    getFinalizedSchedules,
    getDevicesOnlineWithNoGame,
    getRecentSchoolSchedules,
    getGamesWithNotes,
    getDevicesOnlineWithGame,
  };
};
