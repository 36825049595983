import React from 'react';

import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import styles from './Spinner.module.css';
import { SpinnerProps } from './Spinner.types';

const Spinner = ({
  inline = false,
  size = '3x',
  className = '',
  isBlackTheme = false,
}: SpinnerProps) => {
  return (
    <div className={cn(inline ? styles.loadingInline : styles.loading, className)}>
      <FontAwesomeIcon icon={faGear} spin size={size} color={isBlackTheme ? 'var(--white)' : undefined} />
    </div>
  );
};

export default Spinner;
