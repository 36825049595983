/* eslint-disable */
import React from 'react';

import { NavLink } from 'react-router-dom';
import './styles.scss';

export const MenuItem = ({
  link, img, title, onClick, newFlag,
}) => {
  return (
    <NavLink className="menu-item" to={link} onClick={onClick}>
      <div className="menu-item-icon">
        <img src={img} alt={title} /> {title}
        {newFlag && <span className="menu-new-item-flag">new!</span>}
      </div>
    </NavLink>
  );
};
