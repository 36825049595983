import { AxiosInstance } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';
import { ApiKeyGeneratePayload, ApiKeyGenerateResData, Partner } from 'src/services/interfaces/ApiKey.types';
import { Field } from 'src/types';

export const ApiKeyService = (axios: AxiosInstance) => {
  const GetStreamPartners = () => {
    const getStreamPartners = () => axios
      .get(`${apiUrlV4}api-key/stream-partners`)
      .then((res) => {
        const resData: Field[] = res.data.data.map(
          (partner: Partner) => (
            { label: partner.name, value: partner._id }),
        );

        return resData;
      });

    return useQuery(
      'query-get-stream-partners',
      getStreamPartners,
      {
        onError: (error) => {
          console.error('Error in get stream partners:', error);
          snackbarService.error('Something went wrong. Please, try later.');
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  const ApiKeyGenerate = () => {
    const apiKeyGenerate = (payload: ApiKeyGeneratePayload) => axios
      .post(`${apiUrlV4}api-key/generate`, payload).then((res) => {
        const resData: ApiKeyGenerateResData = res.data;
        return resData;
      });

    return useMutation(
      apiKeyGenerate,
      {
        onError: (error) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error in API key generation:', error);
        },
      },
    );
  };

  return {
    GetStreamPartners,
    ApiKeyGenerate,
  };
};
