import { Field } from 'src/types';

export type CountryOptions = 'US' | 'AU' | 'CA';

const countries: Field[] = [
  { value: 'US', label: 'US' },
  { value: 'AU', label: 'AU' },
  { value: 'CA', label: 'CA' },
];

export default countries;
