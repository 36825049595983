/* eslint-disable */
const initialState = {
  // detailed_List:[],
  last_fetched_date: new Date(),

};
const newuserrequest = (state = initialState, action) => {
  switch (action.type) {
    case 'new_users_list':
      return { ...state, detailed_List: action.detailed_List };
    default:
      return state;
  }
};

export default newuserrequest;
