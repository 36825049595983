import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import api from 'src/services/api';

export const useFetchSports = () => {
  const dispatch = useDispatch();
  const { useGetAllSports } = api.sportService();
  const { data, isLoading: isFetching } = useGetAllSports();

  useEffect(() => {
    dispatch({
      type: 'sports/setSports',
      payload: {
        data: data?.data,
        detailedList: data?.detailedList,
      },
    });
  }, [data]);

  return { isFetching };
};
