import { string } from 'yup';

export const colorSchema = string()
  .nullable()
  .test(
    'is-valid-color',
    'Must be in format #XXXXXX',
    (value) => {
      if (!value) {
        return true;
      }

      const colorRegex = /^#[0-9A-Fa-f]{6}$/;
      return colorRegex.test(value);
    },
  );
