export const fullDate: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const monthDayYear: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};

export const monthDay: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
};

export const monthDayShort: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
};

export const hoursMinutes: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};
