import {
  passwordSchema,
} from 'src/schemas';
import { object, string, ref } from 'yup';

export const profileSchema = object().shape({
  password: passwordSchema,
  newPassword: passwordSchema,
  confirmPassword: string().oneOf([ref('newPassword'), null], 'Passwords must match'),
});

export const userSchema = object().shape({
  newPassword: passwordSchema,
  confirmPassword: string().oneOf([ref('newPassword'), null], 'Passwords must match'),
});
