export const selectCountryStyles = {
  container: (provided) => ({
    ...provided,
    marginBottom: 19,
    position: 'relative',
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 50,
    backgroundColor: '#fff',
    borderRadius: 40,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
    padding: 0,
    width: '100%',
    border: state.selectProps.errorMsg ? '1px solid red' : 'none',
    ':hover': {
      boxShadow: '0px -1px 13px rgba(114, 152, 241, 0.5)',
      borderColor: 'red',
    },
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontSize: 16,
    marginLeft: 22,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
  }),
  menu: (provided, state) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 15,
    fontSize: 16,
    zIndex: 15,
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.12)',
    '::after': {
      content: '\' \'',
      position: 'absolute',
      left: 25,
      top: state.menuPlacement === 'top' ? 'auto' : '-30px',
      bottom: state.menuPlacement === 'top' ? '-30px' : 'auto',
      transform: state.menuPlacement === 'top' ? 'rotate(180deg)' : 'rotate(0deg)',
      border: '20px solid transparent',
      borderBottom: '20px solid #fff',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
