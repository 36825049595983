import React from 'react';

import Flags from 'country-flag-icons/react/3x2';

import styles from './SelectCountryValue.module.css';
import { SelectCountryValueProps } from './SelectCountryValue.types';

export const SelectCountryValue = (props: SelectCountryValueProps) => {
  const { innerProps, data } = props;

  const Flag = Flags[data.value];

  return (
    <div {...innerProps} className={styles.selectCountryValueContainer}>
      <Flag
        title={data.value}
        className={styles.selectCountryValueFlag}
      />
      <span className={styles.selectCountryValueText}>{data.value}</span>
    </div>
  );
};
