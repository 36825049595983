import React from 'react';

import cn from 'classnames';
import ChevronIcon from 'src/assets/images/grid/chevron-icon.svg';
import Button from 'src/components/common/button/CustomButton';

import styles from './Columns.module.css';

const Columns = ({ isMenuOpen, handleOpenMenu }) => {

  return (
    <Button
      className={styles.btn}
      onClick={handleOpenMenu}
    >
      Columns
      <img className={cn(styles.chevron, isMenuOpen && styles.open)} src={ChevronIcon} alt="chevron-icon" />
    </Button>
  );
};

export default Columns;
