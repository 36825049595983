import { AxiosInstance } from 'axios';
import { useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';

export interface IGetAllNotifications {
  length: number,
  page: number,
  user_id: string,
  state?: string,
  school_id?: string,
  filters: {
    sortBy: string,
    sort: string
  }
}

export const GetAllNotifications = (axios: AxiosInstance) => {
  return useMutation(
    'mutation-notifications',
    (payload: IGetAllNotifications) => axios.post(
      `${apiUrlV4}notification/get-all`,
      payload,
    ).then((res) => res.data)
      .catch((err) => {
        console.error(err);
        snackbarService.error('Something went wrong while loading all notifications. Please try again later.');
      }),
  );
};
