import React from 'react';

import network from 'src/assets/images/network.svg';
import './styles.scss';

export const NetworkError = ({ onReload }) => {
  return (
    <div className="w-100 network-error">
      <img className="img" src={network} alt="Network Error" />
      <h4 className="title">Connect to the Internet</h4>
      <h6 className="subtitle">You're offline. Check your connection.</h6>
      <button className="retry-btn" onClick={onReload}>Retry</button>
    </div>
  );
};
