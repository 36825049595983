/* eslint-disable */
const initialState = {};
const superadmin = (state = initialState, action) => {
  switch (action.type) {
    case 'SUPERADMIN':
      return { ...state, superAdmin: action.superAdmin };
    case 'POPUPSETUP':
      return { ...state, popup: action.popup };
    default:
      return state;
  }
};

export default superadmin;
