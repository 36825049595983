import { createAction, createReducer } from '@reduxjs/toolkit';

interface TutorialState {
  seasonScheduleTutorial: {
    step: number;
  }
}

const initialState:TutorialState = {
  seasonScheduleTutorial: {
    step: 0,
  },
};

/* Actions */
const setSeasonTutorialStep = createAction('tutorial/seasons/set');

/* Reducer */
export const tutorialReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSeasonTutorialStep, (state, action) => {
      state.seasonScheduleTutorial = action.payload;
    });
});
