import React from 'react';

import cn from 'classnames';
import Select from 'react-select';
import countries from 'src/constants/countries';

import { style } from '../select/Select';
import { SelectCountryOption } from './components/selectCountryOption/SelectCountryOption';
import { SelectCountryValue } from './components/selectCountryValue/SelectCountryValue';
import styles from './SelectCountry.module.css';
import { SelectCountryProps } from './SelectCountry.types';
import { selectCountryStyles } from './selectCountryStyles';

export const SelectCountry = (props: SelectCountryProps) => {
  const {
    menuBar,
    className,
    isLabel,
    value,
    onChange,
    inputRef,
    onBlur,
    menuPlacement = 'auto',
    isDisabled,
    errorMsg,
  } = props;

  return (
    <div className={cn(styles.selectCountryWrapper, isDisabled && styles.disabled)}>
      {isLabel && <div className={styles.selectCountryLabel}>Country</div>}
      <Select
        {...{ errorMsg }} // use this prop to set styles
        className={className}
        placeholder="Select Country"
        aria-label="Select Country" // for readers
        styles={menuBar ? style.menuSelect : selectCountryStyles}
        options={countries}
        value={value}
        onChange={onChange}
        ref={inputRef}
        onBlur={onBlur}
        menuPlacement={menuPlacement}
        components={{ Option: SelectCountryOption, SingleValue: SelectCountryValue }}
        isDisabled={isDisabled}
        isClearable
      />
      {errorMsg && (
        <span
          className={styles.selectCountryErrorMsg}
        >
          {errorMsg}
        </span>
      )}
    </div>
  );
};
