import { AxiosInstance } from 'axios';
// import { useMutation } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV3 } from 'src/services/constants/api-versions';

export const ProfileReportService = (axios: AxiosInstance) => {
  const getProfileReport = ({ email, school_id }) => {
    /**
     * Get profile report for current user
     * @requires {email, school_id}
     * */
    return axios.post(`${apiUrlV3}user/getUserProfileReport`, { email, school_id })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        throw new Error(response.statusText);

      }).catch((err) => {
        snackbarService.error(err.message);
      });
  };

  return { getProfileReport };
};
// TODO: Create Mutation to update user profile using react-query
// export const UpdateUserProfile = (axios: AxiosInstance, userData) => {
//   return useMutation(
//     'update-user-profile',
//     async () => axios.post(`${apiUrlV3}user/updateUserProfile`, { user: userData })
//       .then((res) => res),
//   );
// };
