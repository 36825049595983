import { AxiosInstance } from 'axios';
import { useQuery } from 'react-query';
import { snackbarService } from 'src/components/common/snackbar/snackbar-service';
import { apiUrlV4 } from 'src/services/constants/api-versions';

export const GenderService = (axios: AxiosInstance) => {
  /**
   * Get all genders
   * Save genders in REDUX store
   */
  const GetGenders = () => {
    const getGenders = () => axios.get(`${apiUrlV4}genders`);

    return useQuery(
      'query-get-genders',
      getGenders,
      {
        onError: (error) => {
          snackbarService.error('Something went wrong. Please, try later.');
          console.error('Error in fetching genders:', error);
        },
        refetchOnWindowFocus: false,
      },
    );
  };

  return {
    GetGenders,
  };
};
