import DevicesIcon from 'src/assets/images/menu-icons/devices-icon.svg';
import FacilitiesIcon from 'src/assets/images/menu-icons/facilities-icon.svg';
import GameDayIcon from 'src/assets/images/menu-icons/game-day-icon.svg';
import NotificationIcon from 'src/assets/images/menu-icons/notifications-icon.svg';
// import PastGamesIcon from 'src/assets/images/menu-icons/past-icon.svg';
import PitchTrackerIcon from 'src/assets/images/menu-icons/pitch-icon.svg';
import SchedulesIcon from 'src/assets/images/menu-icons/schedules-icon.svg';
import SchoolsIcon from 'src/assets/images/menu-icons/schools-icon.svg';
import TeamsIcon from 'src/assets/images/menu-icons/teams-icon.svg';
import TwitterIcon from 'src/assets/images/menu-icons/twitter-icon.svg';
import UserIcon from 'src/assets/images/menu-icons/users-ico.svg';
import WidgetsIcon from 'src/assets/images/menu-icons/widgets-icon.svg';

export const adminMenu = [
  {
    link: '/',
    title: 'Game Day',
    icon: GameDayIcon,
  },
  {
    link: '/schedules',
    title: 'Schedules',
    icon: SchedulesIcon,
  },
  {
    link: '/facilities',
    title: 'Facilities',
    icon: FacilitiesIcon,
  },
  {
    link: '/devices',
    title: 'Devices',
    icon: DevicesIcon,
  },
  {
    link: '/schools',
    title: 'Schools',
    icon: SchoolsIcon,
  },
  {
    link: '/teams',
    title: 'Teams',
    icon: TeamsIcon,
  },
  {
    link: '/overlay-builder',
    title: 'Overlay Builder',
    icon: WidgetsIcon,
  },
  {
    link: '/tweetScores',
    title: 'Tweet Scores',
    icon: TwitterIcon,
  },
  {
    link: '/pitch-count',
    title: 'Pitch Count',
    icon: PitchTrackerIcon,
  },
  // {
  //   link: '/games',
  //   title: 'Past Games',
  //   icon: PastGamesIcon,
  // },
  {
    link: '/users',
    title: 'User Access',
    icon: UserIcon,
  },
  {
    link: '/notifications',
    title: 'Notifications',
    icon: NotificationIcon,
  },
];
