import React from 'react';

import { components } from 'react-select';

export const MultiValueRemove = (props) => {
  const { selectProps } = props;

  if (selectProps?.isReadOnly) {
    return null;
  }

  return <components.MultiValueRemove {...props} />;
};
