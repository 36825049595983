import React from 'react';

import { components } from 'react-select';
import AddIcon from 'src/assets/images/add-player.svg';

import styles from './AddButtonFooter.module.css';
import { CustomMenuProps } from './AddButtonFooter.types';

const AddButtonFooter = (props: CustomMenuProps) => {
  const { selectProps, children } = props;
  const { addButtonFooterName, onAddButtonFooterClick } = selectProps;

  return (
    <components.Menu {...props}>
      {children}
      {onAddButtonFooterClick && (
        <div className={styles.container}>
          <button
            className={styles.button}
            onClick={onAddButtonFooterClick}
          >
            <img src={AddIcon} alt="add" />
            <div className={styles.addLabel}>{addButtonFooterName}</div>
          </button>
        </div>
      )}
    </components.Menu>
  );
};

export default AddButtonFooter;
