const padWithZero = (number: number) => {
  return number.toString().padStart(2, '0');
};

const formatTo12Hour = (time: Date) => {
  let hours = time.getHours();
  const minutes = padWithZero(time.getMinutes());

  const timeSuffix = hours >= 12 ? 'PM' : 'AM';

  // convert from 24 to 12 hour format
  hours %= 12;
  hours = hours === 0 ? 12 : hours; // the hour '0' should be '12'

  return `${padWithZero(hours)}:${minutes} ${timeSuffix}`;
};

export const getMonthDayYear = (date: Date) => {
  const day = padWithZero(date.getDate());
  const month = padWithZero(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${month}/${day}/${year} ${formatTo12Hour(date)}`;
};
