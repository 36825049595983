import React from 'react';

import CloseIcon from 'src/assets/images/closeNew.svg';
import Logo from 'src/assets/images/logo/logo-white.svg';
import EmailIcon from 'src/assets/images/modal/email-icon.svg';
import PhoneIcon from 'src/assets/images/modal/phone-icon.svg';
import Button from 'src/components/common/button/CustomButton';
import ModalWindow from 'src/components/common/modals/modal-window/ModalWindow';
import { ContactEnums } from 'src/enums/ContactEnums';

import styles from './MessageModal.module.css';

type MessageModalProps = {
  text: string,
  cancelFn: () => void;
};

const { PHONE_NUMBER, EMAIL } = ContactEnums;

const MessageModalNew = (props: MessageModalProps) => {
  const { text, cancelFn } = props;

  return (
    <ModalWindow isOpen className={styles.modal}>
      <Button version="icon" onClick={cancelFn} className={styles.closeIcon}>
        <img
          src={CloseIcon}
          alt="close"
        />
      </Button>
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.logo} src={Logo} alt="logo" />
          <h2 className={styles.title}>Support</h2>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.contacts}>
          <div className={styles.contactBox}>
            <img className={styles.phoneIcon} src={PhoneIcon} alt="phone-icon" />
            <div className={styles.contactContent}>
              <span className={styles.contactText}>Phone</span>
              <span className={styles.contactConnect}><a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></span>
            </div>
          </div>
          <div className={styles.contactBox}>
            <img className={styles.emailIcon} src={EmailIcon} alt="email-icon" />
            <div className={styles.contactContent}>
              <span className={styles.contactText}>Email</span>
              <span className={styles.contactConnect}><a href={`mailto:${EMAIL}`}>{EMAIL}</a></span>
            </div>
          </div>
          <Button className={styles.backBtn} onClick={cancelFn}>Go back</Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default MessageModalNew;
