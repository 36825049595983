/* eslint-disable */
const games = (state = {}, action) => {
  switch (action.type) {
    case 'current_game_page':
      return { ...state, game: action.game };
    default:
      return state;
  }
};

export default games;
